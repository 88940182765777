






































































































































































































































































































































































































































































































import mixins from 'vue-typed-mixins'
import FlexibleBookingV1Calendar from '@/calendesk/sections/section/shared/mixins/FlexibleBookingV1Calendar'
import EmployeePicker from '@/calendesk/sections/section/shared/components/EmployeePicker.vue'
import BookingTimePicker from '@/components/BookingTimePicker.vue'
import { DateTimeFormats } from '@/calendesk/models/DateTimeFormats'
import { Moment } from 'moment'
import BookingTimeSlot from '@/calendesk/models/BookingTimeSlot'
import BookingEvent from '@/calendesk/models/BookingEvent'
import { errorNotification } from '@/calendesk/prototypes/notifications'
import CTimeZonesSelect from '@/components/CTimeZonesSelect.vue'
import CCalendarService from '@/components/CCalendarService.vue'
import CLocationSelect from '@/components/CLocationSelect.vue'
import CInputStepper from '@/components/CInputStepper.vue'
import CCalendarEmployee from '@/calendesk/sections/section/calendars/calendarV2/components/CCalendarEmployeePanel.vue'

export default mixins(FlexibleBookingV1Calendar).extend({
  name: 'FlexibleBooking3',
  components: {
    CCalendarEmployee,
    CInputStepper,
    CLocationSelect,
    CCalendarService,
    CTimeZonesSelect,
    BookingTimePicker,
    EmployeePicker
  },
  computed: {
    getAllRangeDates (): Array<Record<string, any>> {
      const result = []
      if (this.getStartDate) {
        for (const n in [...Array(7).keys()]) {
          const dateClone = this.getStartDate.clone().add(n, 'days') as Moment
          result.push({
            instance: dateClone,
            date: dateClone.format(DateTimeFormats.FULL),
            dayShort: dateClone.format('dd'),
            dayNumber: dateClone.format('D')
          })
        }
      }

      return result
    },
    getCalendarRangeInfo (): string {
      const startDate = this.getStartDate.startOf('week').format('Do MMM')
      const endDate = this.getEndDate.format('Do MMM')
      return startDate + ' - ' + endDate
    },
    smallScreen (): boolean {
      return this.$vuetify.breakpoint.width <= 450
    },
    getCartMaxHeight (): string {
      return String(parseInt(this.data.configuration.wb_height__style_height__) + 15) + 'px'
    }
  },
  mounted () {
    this.countAllFreePlaces = this.data.configuration.wb_count_places_in_dates__checkbox__
  },
  methods: {
    addDuration (time: string) {
      if (this.selectedSelectableService) {
        return this.$moment(this.selectedDate + ' ' + time)
          .add(this.selectedSelectableService.getDuration(), 'minutes')
          .format(DateTimeFormats.TIME)
      }

      return time
    },
    isDateButtonActive (date: Moment): boolean {
      return (date.isSameOrAfter(this.now) || date.format(DateTimeFormats.FULL) === this.now.format(DateTimeFormats.FULL)) &&
        !!(!this.minDate || (this.minDate && date.isSameOrAfter(this.$moment(this.minDate, DateTimeFormats.FULL)))) &&
          !!(!this.maxDate || (this.maxDate && date.isSameOrBefore(this.$moment(this.maxDate, DateTimeFormats.FULL))))
    },
    addToCart (slot: BookingTimeSlot) {
      if (!this.canAddToCart(slot)) {
        errorNotification('no_spots_left', null, false)
        return
      }

      if (this.selectedSelectableService) {
        this.userRequestedEmployee = !!this.selectedEmployeeId
        const employee = this.getSelectedEmployeeForTime(slot)

        if (employee) {
          const event = new BookingEvent(
            this.selectedSelectableService.service,
            employee,
            this.selectedSelectableService.serviceType,
            slot.date as string,
            slot.time as string,
            null,
            null,
            null,
            this.customerTimeZone,
            this.userRequestedEmployee,
            this.selectedLocation
          )

          this.bookingCartSlots.push({
            key: this.getKeyForSlot(slot),
            event: event
          })
        }

        this.scrollToBottomBookingCardSlotsComponent()
      }
    },
    removeFromCart (slot: BookingTimeSlot, skipStepperNotification = false) {
      return this.removeFromCartByKey(this.getKeyForSlot(slot), skipStepperNotification)
    },
    removeFromCartByKey (key: string, skipStepperNotification = false): void {
      const index = this.bookingCartSlots.findIndex(item => item.key === key)

      if (index !== -1) {
        this.bookingCartSlots.splice(index, 1)

        if (!skipStepperNotification) {
          this.decrementStepperComponent(key)
        }
      }
    },
    decrementStepperComponent (key: string): void {
      const stepperComponents = this.$refs[`stepper_ref_${key}`] as Array<any>
      const stepperComponent = stepperComponents?.[0]

      if (stepperComponent && typeof stepperComponent.decrementSilently === 'function') {
        stepperComponent.decrementSilently()
      }
    },
    scrollToBottomBookingCardSlotsComponent () {
      this.$nextTick(() => {
        const container = document.getElementById('booking-card-slots-container-list')
        if (container) {
          container.scrollTop = container.scrollHeight
        }
      })
    },
    calendarReloadWithPrev () {
      this.isFetchingTimeSlots = true
      const selectedDate = this.$moment(this.selectedDate, DateTimeFormats.FULL).subtract(7, 'days').format(DateTimeFormats.FULL)

      if (this.minDate && this.$moment(selectedDate, DateTimeFormats.FULL).isBefore(this.$moment(this.minDate, DateTimeFormats.FULL))) {
        this.selectedDate = this.minDate
      } else {
        this.selectedDate = selectedDate
      }

      this.reloadEventsDebounce()
    },
    calendarReloadWithNext () {
      this.isFetchingTimeSlots = true
      const selectedDate = this.$moment(this.selectedDate, DateTimeFormats.FULL).add(7, 'days').format(DateTimeFormats.FULL)

      if (this.maxDate && this.$moment(selectedDate, DateTimeFormats.FULL).isAfter(this.$moment(this.maxDate, DateTimeFormats.FULL))) {
        this.selectedDate = this.maxDate
      } else {
        this.selectedDate = selectedDate
      }

      this.reloadEventsDebounce()
    },
    bookSelectedTimeSlotOrOpenCart (slot: BookingTimeSlot) {
      if (this.bookingCartSlots.length > 0) {
        this.bookFromCart()
      } else {
        this.bookSelectedTime(slot)
      }
    },
    getDateText (date: any) {
      return `${date.dayShort} - ${date.date} (${this.numberOfHoursForDate(date.date)})`
    }
  }
})
